.dashboard-section {
  width: 100%;
  background-color: white;
  display: flex;
  margin-top: -30px;
  padding-top: 20px;
}
.dashboard-left {
  width: calc(100% - 150px);
}
.dashboard-header {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 0px 0px 10px;
  margin-bottom: 20px;
}
.register-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.registr-label {
  margin-bottom: 7px;
  font-weight: 700;
  text-align: start;
  font-family: "Times New Roman", Times, serif;
  font-size: 25px;
}
.register-Div {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-Div-input {
  width: 250px !important;
  font-size: 18px;
  padding: 7px;
}
.register-Div-button {
  height: 44px !important;
  font-size: 18px;
  padding: 7px;
}
.registir-modal-content {
  padding: 30px;
  background-color: rgb(85, 71, 71);
}
.dashboard-content {
  display: flex;
}
.dashboard-content .anticon svg {
  color: black;
}
.dashboard-content-left {
  width: 45%;
  height: 78vh !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 15px;
  margin-right: 10px;
  padding: 5px;
  overflow-y: auto;
}
.dashboard-catagory-head {
  padding-left: 0 !important;
}
.dashboard-right {
  width: 150px;
  height: 78vh;
  overflow: auto;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 10px;
}
.dashboard-content-right {
  width: 55%;
  position: relative;
  margin-right: 10px;
}
.App ::-webkit-scrollbar {
  width: 5px;
}
.App ::-webkit-scrollbar-track {
  background: white;
}
.App ::-webkit-scrollbar-thumb {
  background: #64b3f4;
}
.App ::-webkit-scrollbar-thumb:hover {
  background: #64b3f4;
}
.noZakaz {
  width: 100%;
  height: 100px;
  padding: 10px;
  background: #e55d87; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #5fc3e4,
    #e55d87
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #5fc3e4,
    #e55d87
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: black;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.dashboard-booking-card {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  color: white;
  margin-bottom: 10px;
  background: #43cea2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #185a9d,
    #43cea2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.dashboard-content-booking {
  display: block;
  width: 100%;
  background-color: white;
  z-index: 2;
  height: 78vh;
  overflow: auto;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 5px 10px;
}
.dashbord_h1 {
  color: rgb(235, 228, 228);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  line-height: 17px;
}

.color {
  background: #f09819; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #edde5d,
    #f09819
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -webkit-linear-gradient(
    to right,
    #edde5d,
    #f09819
  ); /* Chrome 10-25, Safari 5.1-6 */
}
.color2 {
  background: #ee0979 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.example {
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
/* CSS */
.dashboard_reset__button {
  float: right;
  font-size: 18px;
  padding-bottom: 5px; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.button-64 {
  float: right;
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 14px;
  justify-content: center;
  line-height: 0.5em;
  max-width: 100%;
  min-width: 120px;
  padding: 2px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 14px 20px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
  font-size: 16px;
}

.button-64:hover span {
  background: none;
}

.send-time {
  background-color: yellow;
  cursor: pointer;
}
.selected-time {
  cursor: pointer;
  background: none;
}
