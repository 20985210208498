/* Global styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style-type: none;
}

@font-face {
  font-family: "Lobster1.4Regular";
  src: url(fonts/lobster-webfont.ttf);
}
@font-face {
  font-family: "NunitoRegular";
  src: url(fonts/nunito-regular-webfont.ttf);
}
@font-face {
  font-family: "LimelightRegular";
  src: url(fonts/limelight-webfont.ttf);
}

.admin-panel {
  min-height: 100vh;
}
.trigger {
  font-size: 26px;
  color: white;
  margin-left: 20px;
}
.logo {
  color: white;
  padding: 16px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 30px;
  background-color: rgb(58, 19, 95);
  margin: 20px;
}
.info-div {
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 50%;
  background-color: rgb(221, 202, 202);
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_E7iizhldbXevyrRdTeGX01d8GV5d6tmTJg&usqp=CAU");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: red;
  margin: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
}
.pointer {
  cursor: pointer;
}
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.time {
  background-color: white;
}
.time-red {
  background-color: red;
  color: white;
}
.time-green {
  background-color: rgb(5, 230, 16);
  color: white;
}
.width-60 {
  width: 60%;
}
.flex-service {
  display: flex;
  min-width: 200px;
  align-items: center;
}
.service-timetable {
  min-height: 40vh;
}
.ck-content {
  min-height: 30vh;
}
.layour_header .anticon svg {
  color: white;
}

.main_responsive_content {
  overflow-x: auto;
}
.client_section {
  min-width: 1200px;
}
.complain-section {
  min-width: 1000px;
}
.dashboard-section {
  min-width: 1100px;
}
.doctors {
  min-width: 1150px;
}
.info-content {
  min-width: 800px;
}
.packet-section {
  min-width: 1000px;
}
.service {
  min-width: 1100px;
}
.toll-section {
  min-width: 800px;
}
.left_leyout,
.right_layout {
  height: 100vh;
  /* overflow-y: auto; */
}
.ant-layout-content {
  background-color: white;
}
