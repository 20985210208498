.client_section {
  min-height: 80vh;
  padding: 15px 15px 50px;
  background-color: white;
}
.confirm-input {
  display: inline-block;
  width: 120px;
}
.confirm-button {
  display: inline-block;
}
.client_header {
  width: 100%;
  padding: 33px;
  display: flex;
  background-color: white;
  height: 100px;
}
.client_header h2 {
  margin-right: 30px;
}
.confirm_table td {
  padding: 5px 0 5px 10px;
  text-transform: capitalize;
}
.confirm_table th {
  text-align: right;
}
.confirm_h4 {
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
  border-top: 0.5px solid black;
}
