.doctors {
  background-color: white;
}
.doctors-head {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  padding: 20px;
}
.doctors-head h2 {
  margin: 0;
}
.delete-doctors {
  font-size: 16px;
  color: red;
  margin-right: 30px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.edit-doctors {
  font-size: 16px;
  color: green;
  /* border: 1px solid green; */
  cursor: pointer;
}
.doctors-search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.btn-none {
  display: none;
}

.doctor-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.doctor-btns button {
  padding: 2px 12px;
  border-radius: 4px;
}
.batafsil-doctor {
  width: 100%;
}
.batafsil-doctor tr {
  border: 1px solid #f0f2f5;
}

.batafsil-doctor th {
  width: 30%;
}
.batafsil-doctor td {
  flex: 1 1 auto;
  width: 100%;
}
.batafsil-doctor td,
.batafsil-doctor th {
  padding: 10px 20px;
}
.doktor-info-modal .ant-modal-footer button {
  display: none;
}
.batafsil-doctor-add input {
  width: 100%;
  padding: 10px;
  border: 1px solid #f0f2f5;
}
.doctorModalBoxs {
  width: 100%;
}

.isServic {
  text-align: center;
  background-color: #ff4d4f;
  color: #fff;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  width: 25%;
}
.noServic {
  width: 25%;
  text-align: center;
  align-items: center;
  background-color: rgb(164, 98, 226);
  font-weight: bold;
  color: #fff;
  font-size: 1.2rem;
}
.InDoc {
  background-color: rgb(164, 98, 226);
  color: #fff;
  font-size: 16px;
}
.outDoc {
  font-size: 16px;
}

.tag-service {
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
}
