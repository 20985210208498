.toll-section{
    min-height: 70vh;
    background-color: white;
    padding: 0 16px;
}
.info-sticky{
    position: sticky !important;
    top: 0 !important;
}
.doctors-head{
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    min-height: 10vh;
    padding: 20px;
    /* position: fixed;
    top: 0px;
    right: 0; */
}
.tool-header h1{
    margin: 0px 50px 0 40px;
    padding-top: 20px;
    font-size: 25px;
    font-weight: bold;
}
.delete-doctors{
    font-size: 16px;
    color: red;
    margin-right: 30px;
    /* border: 1px solid red; */
    cursor: pointer;
}
.edit-doctors{
    font-size: 16px;
    color: green;
    /* border: 1px solid green; */
    cursor: pointer;
}
.doctors-search{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.btn-none{
    visibility: hidden;
}

.doctor-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
}
.doctor-btns button{
    padding: 2px 12px;
    border-radius: 4px;
}
.tool-btns{
    float: left;
}
.ant-tooltip-inner {
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
    display: none !important;
}
.info-div-space{
    height: 15px;
}
.shrink-info{
    height: 20px;
    overflow: hidden;
}
.read-info-section{
    display: flex;
}
.read-info-left{
    width: 40%;
}
.read-info-rigth{
    width: 60%;
}