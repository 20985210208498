.btn-amal {
  margin-right: 10px;
}
#categoryName {
  width: 70%;
  border: 1px solid #d9d9d9;
  margin: 15px 0;
  padding: 6px;
  outline-color: rgba(0, 0, 255, 0.486);
}
.category-label {
  font-weight: 500;
}
.category-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}
.category-head h3 {
  margin-right: 20px;
}
.select-category {
  display: flex;
}
.label-category {
  margin-right: 20px;
}
.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.timeTable-title {
  background-color: #fafafa;
}
.timeTable-title-active {
  background-color: rgb(0, 255, 0);
  color: white;
}
.timeTable-content {
  min-height: 50vh;
}

.service-name-modal {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
}
.service-name-modal-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 10px;
  background-color: white;
}
.marginr-20 {
  margin-right: 20px;
}
.marginb-20 {
  margin-bottom: 20px;
}
.paddingl-10 {
  padding-left: 10px;
}
.timetable-live .ant-card-body {
  padding: 0;
}
.service-time-active {
  background-color: rgb(62, 62, 255);
}
.service-form {
  width: 75%;
  margin: 0 auto;
}
.service {
  background-color: white;
  padding: 15px;
}
