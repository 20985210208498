.services_block {
  width: 100%;
}

.checkbox_btn {
  width: 220px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}
