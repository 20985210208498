* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.info_section {
  position: relative;
}
.info-content {
  width: 100%;
  min-height: 70vh;
  border: 1px solid #eee;
  background-color: white;
  font-size: 20px;
  padding: 0px 50px 100px;
}
.info-content label {
  font-weight: 500 !important;
}
.info-content br {
  margin-bottom: 50px !important;
}
.info-left {
  width: 100%;
  padding: 0px 50px 10px;
  text-align: left;
}
.info-left-table {
  width: 100%;
}
.info-left-header {
  text-align: center;
  font-weight: bold;
  width: 100%;
  position: sticky;
  top: -24px;
  left: 0;
  background-color: white;
  z-index: 100;
  padding: 25px;
}
.info-content thead {
  height: 60px;
}
.info-left-body {
  padding: 0 20px 20px;
}
.info-left-body th {
  width: 30%;
  text-align: start;
  margin-bottom: auto;
  padding: 10px;
}
.info-left-body td {
  padding-left: 10px;
}
.info-left-body tr {
  padding: 10px !important;
  text-align: start;
  border-bottom: 1px dashed rgb(156, 152, 152);
  min-height: 50px;
}
.info-left-body tr:first-child {
  border-top: none;
}
.see-image {
  margin-top: 30px;
}

.info-right {
  width: 100%;
  padding: 20px;
}
/* create-info */
.create-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crate-info {
  margin: 30px;
  padding: 5px;
  float: right;
}
.info-div-space {
  height: 15px;
}
.info-row {
  display: flex;
  justify-content: space-between;
}
.info-row div {
  width: 40%;
}
.clinic_modal__location {
  display: flex;
}
.clinic_modal__region {
  width: 50%;
  padding-right: 20px;
}
.clinic_modal__district {
  width: 50%;
  margin-bottom: 1rem;
}
.info_images__div {
  display: flex;
}
.info_img__wrap {
  width: 104px;
  height: 104px;
  margin: 0 8px 0px 0;
  vertical-align: center;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  position: relative;
}
.info_img__wrap:hover .info_img__up {
  opacity: 1;
}
.info_img {
  width: 88px;
  height: 88px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.info_img img {
  width: 100%;
}
.info_img__up {
  width: 88px;
  height: 88px;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  color: white;
  font-size: 18px;
  transition: 0.3s;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.info-content .ant-upload-list-picture-card-container {
  display: none;
  margin: 0;
  width: 0;
  height: 0;
}
.info_langugage__parents {
  position: relative;
}
.info_language__div {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 150;
  margin-bottom: 10px;
}

.info_title {
  border: 1px solid grey;
  border-radius: 20px;
  width: 230px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
